@import "@/shared/variables";


.container {
    border: $border $color-gray-light;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    padding: 15px;
    display: flex;
    flex-flow: column nowrap;
}

.empty-state-container{
    width: 100%;
    height: 100%;
    background-color: $color-gray-light;
    color:  $color-gray-dark;
    display: flex;
    border-radius: 10px;
    user-select: none;
    flex-flow: column wrap;
    align-content: center;
    justify-content: center;
    text-align: center;
}

.title { 
    font-weight: bolder;
    color: $color-blue-dark;
    font: normal normal 900 20px/27px Nunito Sans;
    display: flex;
}

.title>span {
    margin-left: auto;
}

.cards-container {
    display: flex;
    flex-flow: column nowrap;
    border: $border $color-gray-light;
    border-radius: 10px;
    padding: 10px;
    flex-basis: 100%;

}

.card {
    display: grid;
    grid-template-columns: 20% 50% 30%;
    padding: 15px;
    margin-bottom: 10px;
    border: $border $color-gray-light;
    border-radius: 10px;
}

.card p{
    margin-bottom: 8px;
}

.card p>span{
    color: #002554;
    font-weight: bolder;
}

.card p>span>span{
    color: #424242;
    font-weight: medium;
}

.card .value span:first-child {
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    color: $color-blue-dark;
    text-align: center;
    font: normal normal 900 30px/22px Nunito Sans;
}

.card>.value>span {
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    color: $color-blue-dark;
    text-align: center;
    font: normal normal 900 14px/22px Nunito Sans;
}

.circle {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: $color-blue-light;
    position: absolute;
}

.card>div:last-child {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.card>div:last-child>div>p {
    color: $color-blue-dark;
    margin-bottom: 0;
}

.card>div:last-child>div>p>span {
    font-weight: bold;
}

// "ver detalle" anchor tag
.card>div:last-child>div:last-child {
    margin-left: auto;
}


@media screen and (max-width: 768px) {
    .card>div:last-child {
        flex-direction: column;
    }
}


.container>div:last-child {
    display: flex;
    justify-content: flex-end;

    margin-top: 20px;
    margin-bottom: 20px;
}