.container-slider{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.slider {
  border-radius: 15px;
  border: 1px solid #D8D8D8;
  width: 100%;
  height: 201px;
  margin: 0 ;
  /* padding: 10px 20px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: start;
  flex-direction: column;
  padding-left: 7px;
  padding-right: 7px;

}

.items-container {
  display: flex;
  column-gap: 5px;
  justify-content: space-between;
  position: relative;
  height: 120px;
  width: 100%;
  overflow: hidden;
}

.arrow-left{
  width: 18px;
  height: 60%;
  display: flex;
  background: #FFF;
}

.arrow-right{
  width: 18px;
  height: 60%;
  display: flex;
  background: #FFF;
}

.items-title {
  color: #002554;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  margin: 0;
}

.item-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 11px;
  min-width: 205px;
}

.items {
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;
  transition: scroll 0.3s ease-in-out;
  width: 100%;
}

.item-container-description {
    display: flex;
    column-gap: 25px;
    height: 200px;
    width: 100%;
    overflow: hidden;
}

.item-info {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.item-title {
    color: #002554;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0px;
    margin: 0;
    opacity: 1;
    text-align: left;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 11vw;
}

.item-decription {
    color: #424242;
    font-size: 12px;
    letter-spacing: 0px;
    margin: 0;
    opacity: 1;
    text-align: left;
}

.button {
    align-items: center;
    background: none;
    border-radius: 14px;
    border: 1px solid #165EEB;
    color: #165EEB;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    height: 28px;
    justify-content: center;
    opacity: 1;
    width: 162px;
}

.link {
    background: none;
    border: none;
    color: #00AEEF;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    justify-content: flex-end;
    letter-spacing: 0px;
    margin: 0;
    opacity: 1;
    text-decoration: underline;
    width: 100%;
    margin-top: 5px;
}

.button p {
  margin: 0;
}

.link p {
  margin: 0
}

.none {
  display: none;
}


@media screen and (min-width: 758px) {
  .items-title {
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .slider {
  }

  .item-title {
    font-size: 14px;
  }

  .item-decription {
    font-size: 14px;
  }

  .items {
    gap: 20px;
  }

  .items-container {
    column-gap: 10px;
  }
}

