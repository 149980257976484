@import "@/shared/variables";

.container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-carousel{
    width: 100%;
    position: relative;
    // height: 250px
}
.img-text {
    position: absolute;
    z-index: 100;
    width: 200px;
    font-size: 0.8rem;
    left: 50px;
    top: 50%;
    transform: translate(0%, -50%);
    color: white;
}
.img-text > p:first-child{
    font-size: 0.95rem;
    font-weight: bold;
}
@media screen and (max-width: 525px) {
    .container {
        width: 91%;
       
    }
    .img-text > p:first-child{
        font-size: 0.9rem;
        font-weight: bold;
    }
    .img-text {
        width: 120px;
        font-size: 0.55rem;
        left: 20px;
        color: white;
    }
}
@media screen and (max-width: 537px) {
    .container {
        width: 88%;
       
    }
}
@media screen and (max-width: 487px) {
    .container {
        width: 80%;
       
    }
}
@media screen and (max-width: 445px) {
    .container {
        width: 75%;
       
    }
}
@media screen and (max-width: 429px) {
    .container {
        width: 100%;
       
    }
}
.img-carousel img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 27px;
    overflow: hidden;
}
