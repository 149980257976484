.input-container input {
  border: 1px solid #D8D8D8;
  border-radius: 19px;
  width: 100%;
  font-family: 'Nunito Sans', sans-serif;

  ::placeholder {
    color: #424242;
    font-size: 12px;
    font-family: 'Nunito Sans', sans-serif;
  }
}

.input-container {
  position: relative;
  width: 100%;
}

.input-container-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

.input-container input {
  padding: 5px 38px 5px 15px;
}

.input-container input:focus {
  border: 1px solid #165EEB;
}

.input-container label {
  margin-bottom: 10px;
}



.input-orders input {
  border: 1px solid #D8D8D8;
  border-radius: 19px;
  width: 100%;
  font-family: 'Nunito Sans', sans-serif;

  ::placeholder {
    color: #424242;
    font-size: 12px;
    font-family: 'Nunito Sans', sans-serif;
  }
}

.input-orders {
  position: relative;
  width: 100%;
}

.input-orders-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

.input-orders input {
  padding: 5px 38px 5px 15px;
}

.input-orders input:focus {
  border: 1px solid #165EEB;
}

.input-orders label {
  margin-bottom: 10px;
}

.input-orders{
  width: 90%;
}

@media screen and (min-width: 758px) {
  .input-container {
    width: calc(100% - 63%);
  }

  .input-orders {
    width: calc(100% - 63%);
  }
}

@media screen and (max-width: 900px) {

  .input-orders {
    width: 95%
  }
}