@import "@/shared/variables";

.tooltip {
  position: absolute;
  border: 1px solid $color_blue_light;
  border-radius: 15px;
  padding: 20px;
  background: $color-white;
  z-index: 10;
  top: 45px;
  width: 279px;
  row-gap: 10px;
  display: flex;
  flex-direction: column;
}

.item-tooltip {
  display: flex;
  column-gap: 15px;
  align-items: center;
}

.item-tooltip p {
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  color: $color_blue_light;
}

.item-tooltip-svg svg{
  width: 15px;
  height: 15px;
}

.tooltip button{
  text-decoration: none;
}

.shopping-list-description {
    color: #424242;
    font-size: 14px;
    margin: 0;
    text-align: center;
}
