@import '@/shared/variables';

.description-container {
  border-radius: 5px;
  opacity: 1;
  background: $color-white;
  padding: 10px 15px;
  width: 100%;
  margin-top: 20px;
  border: 1px solid #d8d8d8;
}

.container {
  width: 95%;
}

.container h1 {
  letter-spacing: 0px;
  color: #002554;
  opacity: 1;
  font-size: 32px;
  font-weight: bold;
}

.select-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  justify-content: space-between;
}

@media screen and (max-width: 900px) {
  .select-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
  }
  .input-id{
    width: 90% !important;
  }
}

.status-circle-green-table {
  background: #28a745;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.status-circle-blue-table {
  background: #00aeef;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.status-circle-orange-table {
  background: #ffc252;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.status-circle-blue-cloud-table{
  background: #165EEB;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.status-circle-red-table {
  background: #f42f43;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.status-circle-yellow-table {
  background-color: rgb(255, 255, 0);
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.Complete {
  background: #002554;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.status-incidence {
  background: #eb8500;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.body-link {
  color: #00aeef;
  font-size: 10px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.cell {
  display: flex;
  align-items: center;
  column-gap: 5px;
  text-align: right;
}

.order-title {
  font-size: 32px;
}

.detalle a {
  color: #165eeb;
  font: normal normal bold 14px/19px Nunito Sans;
}

.date-container {
  display: none;
}

.datepicker-container {
  position: absolute;
  z-index: 3;
  top: 47px;
  left: 16px;
}

.filter-container {
  position: relative;
}
