.categorie-title {
    color: #002554;
    font-weight: bold;
    margin-bottom: 19px;
    font-size: 26px;
}

.container-categories {
    column-gap: 12px;


    margin-top: 10px;


    @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
        row-gap: 10px;
        column-gap: 20px;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        display: flex;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
    }

}

.container-categories>div {
    scroll-snap-align: start;
    flex-shrink: 0;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 100px;
}

.slides-container {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-basis: 100%;
    min-height: 172px;
    // padding: 8px;
    flex-direction: column;

    @media screen and (min-width: 1100px) {
        flex-direction: row;
        flex-wrap: wrap;
    }


    @media screen and (max-width: 768px) {
        row-gap: 30px;
        max-width: calc(100vw - 2rem);
    }
}

.slides-container>div:first-child {
    width: 524px;
    margin: auto;

    @media screen and (max-width: 440px) {
        width: calc(100vw - 2rem);
        max-width: calc(100vw - 2rem);
    }
}

.slides-container>div:last-child {
    width: calc(100% - 524px);
    @media screen and (max-width: 440px) {
        width: calc(100vw - 2rem);
        max-width: calc(100vw - 2rem);
    }
    @media screen and (max-width: 1100px) {
        width: 100%;
    }

}


.cards-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        gap: 16px;
    }

    @media screen and (min-width: 1024px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr auto;
        gap: 16px;
        
    }

    @media screen and (max-width: 768px) {
        row-gap: 30px;
    }
}

.container {
    display: flex;
    flex-flow: row wrap;
    row-gap: 30px;

    @media screen and (min-width: 768px) {
        gap: 18px
    }
}

.item-cards {
    width: 100%;
    min-height: 474px;

    @media screen and (min-width: 768px) {
        width: 50%;
    }

    @media screen and (min-width: 1024px) {
        width: 100%;
    }
}

.categories {
    
    @media screen and (min-width: 200px) {
        width: 87vw;
    }
    @media screen and (min-width: 768px) {
        width: 100%;
    }
}