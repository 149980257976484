@import '@/shared/variables';

.product-list-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.product-list-page-items {
  //   display: flex;
  //   flex-flow: row wrap;
  //   padding-bottom: 120px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-flow: row wrap;
  padding-bottom: 120px;

  @media (max-width: 645px) {
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin-left: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-flow: row wrap;
    padding-bottom: 120px;
  }
}

.item-container {
  width: 203px;
  height: 332px;
  margin-right: 16px;
  display: grid;
  grid-template-columns: 0.7fr 0.3fr;
  grid-template-rows: 200px 1fr 0.3fr;
  grid-template-areas:
    'gallery-item gallery-item'
    'item-description item-description'
    'item-add item-add'
    'item-button .';
  margin-bottom: 30px;

  @media (max-width: 576px) {
    width: calc(50% - 8px);
    margin-right: 8px;
    margin-bottom: 16px;
  }

  @media (max-width: 400px) {
    width: calc(68% - 8px);
    margin-right: 8px;
    margin-bottom: 16px;
  }
}

.gallery-item {
  grid-area: gallery-item;
  width: 100%;
  height: 100%;
}

.item-title {
  grid-area: item-title;
  width: 100%;
  height: 100%;
  color: $color-blue-dark;
  font: normal normal bold 14px/22px Nunito Sans;
}

.item-description {
  grid-area: item-description;
  width: 100%;
  height: 115px;
  color: $color-blue-dark;
  font-size: 14px;
  padding-bottom: 30px;
}

.item-add {
  grid-area: item-add;
  width: 100%;
  height: 100%;
  margin-bottom: 12px
}

.item-button {
  grid-area: item-button;
  width: 100%;
  height: 100%;
}

.gallery-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  column-gap: 15px;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  transition-duration: 0.5s;
  transform: scale(1.2);
}

.current-slide {
  transition-duration: 0.5s ease;
}

@media screen and (min-width: 989px) {
  .product-list-page-items {
    //   display: flex;
    //   flex-flow: row wrap;
    //   padding-bottom: 120px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    flex-flow: row wrap;
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 400px) {
  .product-list-page-items {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 120px;
  }

  .gallery-item img {
    width: 115px;
    height: 115px;
    transition-duration: 0.5s;
    transform: scale(1.2);
    width: 172px;
  }
}

@media screen and (max-width: 699px) {
}


// Modal

.flex-items{
  display: flex;
  justify-content: space-between;
}

.item-modal-add {
  width: 130px;
  height: 36px;
}

.item-img{
  width: 95px !important;
  height: 95px !important;
}

.product-flex {
  display: flex;
  justify-content: start;
  margin-bottom: 20px;
  align-items: center;
  margin-left: 14%;
}

.product-img {
  margin-right: 16px;
}

.product-info {

}

.text-info {
  font-size: 16px;
  font-weight: bold;
}

.text-price {
  font-size: 20px;
  font-weight: bold;
}