@import '@/shared/variables';

.contenedor {
  /* Ocupa todo el ancho disponible en su contenedor (columna de la cuadrícula) */
  min-width: 0; /* Establece el ancho mínimo del contenedor (puede necesitarse para anular estilos anteriores) */
  border: 1px solid #d8d8d8; /* Solo para visualización */
  border-radius: 10px; /* Valor para redondear las esquinas */
  padding: 20px; /* Espaciado interno, ajusta según sea necesario */
  box-sizing: border-box; /* Asegura que el tamaño total incluya el borde y el relleno */
  min-height: 500px;
  height: 100%;
}

.cotenedor-ordenes {
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
}

.contenedor-tabla-ordenes {
  margin: 20px 0;
}

.contenedor-titulos {
  display: flex;
  justify-content: space-between;
}

.contenedor-status {
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  padding: 10px 5px;
  width: 20%;
  // max-width: 100px;
  height: 93px;
  box-sizing: border-box;
  position: relative;
}

.texto-ecommerce {
  color: $color-blue-dark;
  font-size: 20px;
  font-weight: bold;
}

.status-circle-green {
  width: 10px;
  height: 10px;
  background-color: $color-green;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 10px;
}

.status-circle-yellow {
  width: 10px;
  height: 10px;
  background-color: $color-orange;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 10px;
}

.status-circle-blue {
  width: 10px;
  height: 10px;
  background-color: $color-blue-dark;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 10px;
}

.status-circle-red {
  width: 10px;
  height: 10px;
  background-color: $color-red;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 10px;
}

.status-circle-orange {
  width: 10px;
  height: 10px;
  background-color: $color-orange-dark;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 10px;
}

.text-number {
  color: $color-blue-dark;
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0;
}

.contenedor-align-horizontal {
  display: flex;
  justify-content: center;
}

.text-ordenes {
  text-align: center;
  color: #424242;
  font-size: 0.8rem;
  font-weight: normal;
  margin: 0;
  line-height: 1.2;
}

// Table

.t-head {
  background-color: #002254 !important; /* Color de fondo azul */
  color: white !important;
  text-align: left; /* Texto en color blanco para contrastar */
  font-size: 1rem;
  white-space: nowrap;
}

.td {
  border-left-width: 1px;
  border-right-width: 1px;
}

.status-align {
  display: flex;
  justify-content: start;
}

.status-circle-green-table {
  width: 10px;
  height: 10px;
  background-color: $color-green;
  border-radius: 50%;
  margin-top: 8px;
  margin-right: 4px;
}

.status-circle-orange-table {
  width: 10px;
  height: 10px;
  background-color: $color-orange;
  border-radius: 50%;
  margin-top: 8px;
  margin-right: 4px;
}

.status-circle-blue-table {
  width: 10px;
  height: 10px;
  background-color: $color-blue-dark;
  border-radius: 50%;
  margin-top: 8px;
  margin-right: 4px;
}

.status-circle-red-table {
  width: 10px;
  height: 10px;
  background-color: $color-red;
  border-radius: 50%;
  margin-top: 8px;
  margin-right: 4px;
}

.status-circle-yellow-table {
  width: 10px;
  height: 10px;
  background-color: $color_blue_light_2;
  border-radius: 50%;
}

.contenedor-vertodos {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 1304px) {
  .cotenedor-ordenes > .contenedor-status:last-child {
    display: none;
  }

  .contenedor-status {
    width: 25%;
  }
}

@media screen and (max-width: 1025px) {
  .cotenedor-ordenes > .contenedor-status:last-child,
  .cotenedor-ordenes > .contenedor-status:nth-child(4) {
    display: none;
  }

  .contenedor-status {
    width: 33%;
  }
}

@media screen and (max-width: 769px) {
  .cotenedor-ordenes > .contenedor-status:last-child,
  .cotenedor-ordenes > .contenedor-status:nth-child(4) {
    display: block;
  }

  .contenedor-status {
    width: 20%;
  }
}

@media screen and (max-width: 568px) {
  .cotenedor-ordenes > .contenedor-status:last-child {
    display: none;
  }

  .contenedor-status {
    width: 25%;
  }
}

@media screen and (max-width: 468px) {
  .cotenedor-ordenes > .contenedor-status:last-child,
  .cotenedor-ordenes > .contenedor-status:nth-child(4) {
    display: none;
  }

  .contenedor-status {
    width: 33%;
  }
}
