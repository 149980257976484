.title{
  color: #002554;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  text-align: center;

  @media screen and (min-width: 768px) {
    font-size: 1.2rem;
  }
}

.categorie-title{
  color: #002554;
  font-weight: bold;
}

.card{
  width: 143px;
  height: 143px;
  padding: 20px 20px;
  border-radius: 15px;
  cursor: pointer;
  border: 1px solid #D8D8D8;
  display: flex;
  text-align: start;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
  width: 100%;
  height: 230px;
 }
}

.card > div > img{
  width: 79px;
  height: 79px;

  @media screen and (min-width: 768px) {
    width: 140px;
    height: auto;
  }
}
